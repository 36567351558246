// Size Chart Modal
if (document.querySelector('.js-size-chart-modal')) {
  const open_size_modal = document.querySelectorAll('.js-size-chart-open');
  const close_size_modal = document.querySelector('.js-size-chart-close');
  const overlay_size_modal = document.querySelector('.js-size-chart-overlay');
  const size_modal = document.querySelector('.js-size-chart-modal');
  var size_chart_focusable = size_modal.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
  var focus_on_open;
  var focus_first_tab = true;

  // console.log(size_chart_focusable);

  let toggleSizeChart = function() {
    if (size_modal.classList.contains('hidden')) {

      focus_on_open = document.activeElement;
      document.addEventListener('keydown', trapFocus);

      window.requestAnimationFrame(() => {
        document.body.classList.add('size-chart-open');
        size_modal.classList.add('opacity-0');
        size_modal.classList.remove('hidden');

        window.requestAnimationFrame(() => {
          size_modal.classList.remove('opacity-0');
        });
      });
    } else {
      size_modal.addEventListener('transitionend', () => {
        size_modal.classList.add('hidden');
      }, {once: true});
      document.removeEventListener('keydown', trapFocus);
      focus_first_tab = true;
      document.body.classList.remove('size-chart-open');

      if (focus_on_open) {
        focus_on_open.focus();
        focus_on_open = null;
      }

      window.requestAnimationFrame(() => {
        size_modal.classList.add('opacity-0');
      });
    }
  }

  let trapFocus = function(e) {
    var isTabPressed = (e.key === 'Tab');

    if (!isTabPressed) {
      return;
    }

    let first_focus = size_chart_focusable[0];
    let last_focus = size_chart_focusable[size_chart_focusable.length - 1];

    if (focus_first_tab) {
      first_focus.focus();
      e.preventDefault();
      focus_first_tab = false;
    } else {
      if ( e.shiftKey ) /* shift + tab */ {
        if (document.activeElement === first_focus) {
          last_focus.focus();
          e.preventDefault();
        }
      } else /* tab */ {
        if (document.activeElement === last_focus) {
          first_focus.focus();
          e.preventDefault();
        }
      }
    }
  }

  let setupUnitToggle = function() {
    const unitRadios = size_modal.querySelectorAll('input[name="unit"]');
    if (unitRadios.length === 0) return; // Exit if no radio buttons found

    const inchesChart = size_modal.querySelector('#inches-chart');
    const centimetersChart = size_modal.querySelector('#centimeters-chart');

    unitRadios.forEach(radio => {
      radio.addEventListener('change', function() {
        if (this.value === 'inches') {
          inchesChart.classList.replace('hidden', 'block');
          centimetersChart.classList.replace('block', 'hidden');
        } else {
          inchesChart.classList.replace('block', 'hidden');
          centimetersChart.classList.replace('hidden', 'block');
        }
      });
    });
  }

  open_size_modal.forEach((e) => {
    e.addEventListener('click', () => {
      toggleSizeChart();
      setupUnitToggle();
    });
  });
  close_size_modal.addEventListener('click', toggleSizeChart);
  overlay_size_modal.addEventListener('click', toggleSizeChart);
}
